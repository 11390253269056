import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  NavigationInsightCommentsIcon as CommentsIcon,
  DateAndTimeTimeIcon as ClockIcon,
  NavigationAdministrationAccessControlIcon as LockIcon,
} from '@peakon/bedrock/icons/system';

import Text from 'components/Text';

import styles from './SurveyInfoStats.scss';

export default function SurveyInfoStats({ info }) {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <div className={styles.item}>
        <CommentsIcon />
        <div>
          {t('survey__welcome__approx_questions', {
            count: info.estimated,
          })}
        </div>
      </div>

      <div className={styles.item}>
        <ClockIcon />
        <div>
          <Text
            values={{
              numOfMinutes: Math.round(
                moment
                  .duration({
                    seconds: info.seconds,
                  })
                  .asMinutes(),
              ),
            }}
            interpolate
          >
            survey_welcome_time_icu
          </Text>
        </div>
      </div>

      <div className={styles.item}>
        <LockIcon />
        <div>{t('survey__welcome__anonymous__confidential')}</div>
      </div>
    </div>
  );
}

SurveyInfoStats.propTypes = {
  info: PropTypes.object.isRequired,
};
