import { KIOSK_MODES } from 'constants/kiosk';

import React, { useEffect, useRef, useState } from 'react';

import base64js from 'base64-js';
import { ErrorPage, KioskContent } from 'components';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Context } from 'records';
import { bindActionCreators } from 'redux';
import storejs from 'store';
import { TextDecoderLite } from 'text-encoder-lite';

import { Spinner } from '@peakon/components';

import { unauthenticate } from 'actions/authenticate';
import * as KioskActions from 'actions/kiosk';
import { initLocale } from 'actions/locale';
import { showNotification } from 'actions/notification';
import * as stateUtil from 'lib/stateUtil';
import getQueryFromLocation from 'utils/getQueryFromLocation';

import styles from './Kiosk.scss';
import i18n from '../../i18n';

function Base64Decode(str, encoding = 'utf-8') {
  const bytes = base64js.toByteArray(str);
  return new (window.TextDecoder || TextDecoderLite)(encoding).decode(bytes);
}

export const Kiosk = ({
  actions,
  match,
  history,
  kioskMode,
  location,
  context,
  isAuthenticating,
  error: errorProp,
  showNotification,
  notification,
}) => {
  const [error, setError] = useState(errorProp);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [noSurveys, setNoSurveys] = useState(false);
  const timer = useRef(null);

  useEffect(() => {
    const init = async () => {
      actions.initLocale(i18n.language);

      const getAttributesFromUrl = () => {
        let attributes;
        try {
          const queryParams = getQueryFromLocation(location);

          attributes = JSON.parse(Base64Decode(queryParams.get('attributes')));
        } catch (err) {
          // noop
        }

        if (!isEmpty(attributes)) {
          storejs.set(`attributes_${match.params.scheduleCode}`, attributes);
        }
      };

      try {
        await actions.readCode(match.params.scheduleCode);
        setIsLoading(false);
        getAttributesFromUrl();
      } catch (error) {
        setIsLoading(false);
        setError(error);
      }
    };

    init();

    return () => {
      clearInterval(timer?.current);
    };
  }, [actions, location, match.params.scheduleCode]);

  const handleSubmit = async (kioskCode, firstName) => {
    const { scheduleCode } = match.params;
    setIsSubmitting(true);

    try {
      let responseId;

      if (kioskMode === 'ephemeral') {
        responseId = await actions.createEmployee(scheduleCode);
      } else if (kioskMode === 'employeeLogin') {
        responseId = await actions.employeeLogin({
          kioskCode,
          firstName,
          scheduleCode,
        });
      } else {
        responseId = await actions.authenticate({ kioskCode, scheduleCode });
      }

      if (!responseId) {
        return handleSetNoSurveys();
      }

      history.push('/survey/answer/kiosk');
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const handleBack = () => {
    clearInterval(timer);

    actions.unauthenticate();
    setNoSurveys(false);
  };

  const handleSetNoSurveys = () => {
    setIsSubmitting(false);
    setNoSurveys(true);

    timer.current = setTimeout(() => {
      actions.unauthenticate();
      setNoSurveys(false);
    }, 10000);
  };

  return (
    <div>
      {isLoading ? (
        <div className={styles.loader}>
          <Spinner />
        </div>
      ) : error ? (
        <ErrorPage error={error} />
      ) : (
        <KioskContent
          handleBack={handleBack}
          handleSubmit={handleSubmit}
          noSurveys={noSurveys}
          isSubmitting={isSubmitting}
          isAuthenticating={isAuthenticating}
          error={errorProp}
          context={context}
          kioskMode={kioskMode}
        />
      )}
    </div>
  );
};

Kiosk.propTypes = {
  actions: PropTypes.object.isRequired,
  context: PropTypes.instanceOf(Context),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  match: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  error: PropTypes.object,
  isAuthenticating: PropTypes.bool,
  kioskMode: PropTypes.oneOf(KIOSK_MODES),
  showNotification: PropTypes.bool,
  notification: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    context: state.getIn(['context']),
    error: state.getIn(['authenticate', 'error']),
    isAuthenticating: state.getIn(['authenticate', 'isAuthenticating']),
    responseId: state.getIn(['authenticate', 'response', 'responseId']),
    kioskMode: state.getIn(['kiosk', 'mode']),
    showNotification: state.getIn(['notification', 'show']),
    notification: stateUtil.toJS(['notification', 'data'], state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    Object.assign(
      {
        initLocale,
        showNotification,
        unauthenticate,
      },
      KioskActions,
    ),
    dispatch,
  ),
});

export const KioskContainer = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Kiosk)),
);

export default KioskContainer;
