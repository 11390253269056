import React, { Suspense, Component } from 'react';

import cx from 'classnames';
import { QuestionText, SkipQuestionButton } from 'components';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
  NavigationBasicNavigationChevronDownIcon as ChevronDown,
  NavigationBasicNavigationChevronUpIcon as ChevronUp,
} from '@peakon/bedrock/icons/system';
import { Button } from '@peakon/bedrock/react/button';
import { getUrls } from '@peakon/illustrations';

import ValueCardLoading from 'components/ValueCard/Loading';
import { NEXT_QUESTION } from 'constants/mixpanelEvents';
import Question from 'records/Question';
import { analytics } from 'utils/features/analytics/analytics';

import styles from './QuestionItem.scss';
import questionTransition from './questionTransition.scss';
import QuestionScale from '../QuestionScale';

const ValueCard = React.lazy(() => import('../ValueCard'));

export class QuestionItem extends Component {
  constructor() {
    super();

    this._isMounted = true;
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.onScaleChange = this.onScaleChange.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.skipQuestion = this.skipQuestion.bind(this);
    this.onAnimationStart = this.onAnimationStart.bind(this);
    this.onAnimationEnd = this.onAnimationEnd.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.state = {
      animated: false,
      direction: 'next',
      disablePreviousClick: false,
    };
  }

  componentDidMount() {
    if (isFunction(React.initializaTouchEvents)) {
      React.initializeTouchEvents(true);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onTouchStart(e) {
    if (this.props.inputFocused && e.target.type !== 'textarea') {
      this.setState(
        {
          disablePreviousClick: true,
        },
        () => {
          window.document.activeElement.blur();

          setTimeout(() => {
            this._isMounted &&
              this.setState({
                disablePreviousClick: false,
              });
          }, 300);
        },
      );
    }
  }

  onAnimationStart() {
    this.setState({
      animated: true,
    });
  }

  onAnimationEnd() {
    this.setState({
      animated: false,
    });
  }

  onScaleSet = (scale) => {
    const { question, onScaleSet } = this.props;

    onScaleSet(question.id, scale);
  };

  onScaleUnset = () => {
    const { question, onScaleUnset } = this.props;

    onScaleUnset(question.id);
  };

  onScaleChange(e, keepScaleSelector = false) {
    const { question, onAnswer } = this.props;
    const scale = isNumber(e) ? e : parseInt(e.target.value, 10);

    onAnswer(
      question.answer.merge({
        scale,
        keepScaleSelector,
      }),
    );
  }

  onTextChange(text) {
    const { question, onAnswer } = this.props;

    onAnswer(question.answer.set('text', text));
  }

  previous() {
    if (this.props.isSkipping) {
      this.props.onSkipCancel(this.props.question.id);
    }

    this.setState(
      {
        direction: 'previous',
      },
      () => {
        this.props.onSubmit(true); // true => go to previous
      },
    );
  }

  next() {
    const { onSubmit } = this.props;

    this.setState(
      {
        direction: 'next',
      },
      onSubmit,
    );
  }

  skipQuestion(reason) {
    const { question, onSkip } = this.props;

    onSkip(question.id, reason);
    this.next();
  }

  renderSidebar() {
    const { confirmSubmit, hideIllustrations, isLoading, question } =
      this.props;
    const { direction } = this.state;

    // 'onboarding_group' and 'exit_group' should only render illustration in email notifications. Currently, we do not have assets for their respective questions. This should be removed once we have illustrations for these questions. Refer to the description of this pull request for more context.
    const isOnboardingOrExit = ['onboarding_group', 'exit_group'].includes(
      question?.illustration,
    );

    if (isLoading || !question || hideIllustrations || isOnboardingOrExit) {
      return null;
    } else if (question.type === 'value') {
      return null;
    } else {
      const { identifier, illustration } = question;

      // FIXME: keeping identifier for backwards compatibility
      // with local storage questions
      const urls = getUrls(illustration || identifier, 'v5');

      if (!urls) {
        return null;
      }

      return (
        <div
          key={question.id}
          data-test-id="question-sidebar"
          className={cx(styles.sidebar, {
            [questionTransition.enterNext]:
              !confirmSubmit && direction === 'next' && question,
            [questionTransition.enterPrevious]:
              !confirmSubmit && direction === 'previous' && question,
          })}
        >
          <div>
            <img
              key={question.id}
              className={styles.illustration}
              alt=""
              src={urls.desktop.normal}
              srcSet={`${urls.mobile.normal} 280w, ${urls.mobile['2x']} 620w, ${urls.desktop.normal} 1000w, ${urls.desktop['2x']} 1420w`}
              sizes="(min-width:1420px) 1420px,
                     (min-width:1000px) 1000px,
                     (min-width:620px)  620px,
                     100vw"
            />
          </div>
        </div>
      );
    }
  }

  renderQuestionOrSpinner() {
    const { inputFocused, isLoading, confirmSubmit, isMobile, question, t } =
      this.props;

    if (isLoading || !question) {
      return null;
    }

    return (
      <div
        key={question.id}
        className={styles.questionContent}
        data-type={question.type}
      >
        {question.type === 'value' && (
          <p className={styles.valueText}>
            {t('values_card__title', { valueName: question.value.name })}
          </p>
        )}
        <h1
          ref={this.questionRef}
          id="question-item-title"
          className={styles.text}
          aria-live={confirmSubmit ? undefined : 'assertive'}
        >
          {question.text}
          {inputFocused && isMobile && isNumber(question.answer.scale) ? (
            <div className={styles.focusedScore}>{question.answer.scale}</div>
          ) : null}
        </h1>
        {this.renderValueCard()}
        <div>{this.renderQuestionByType()}</div>
        {question && question.answer.skipReason && isMobile ? (
          <div className="u-visible-mobile">{this.renderSkipButton()}</div>
        ) : null}
      </div>
    );
  }

  renderValueCard() {
    const { isMobile, question } = this.props;

    if (question.type === 'value') {
      return (
        <div className={styles.valueCard}>
          <Suspense fallback={<ValueCardLoading />}>
            <ValueCard isMobile={isMobile} question={question} />
          </Suspense>
        </div>
      );
    }

    return null;
  }

  renderQuestionByType() {
    const { inputFocused, isMobile, onBlur, onFocus, question } = this.props;

    if (question.type === 'scale' || question.type === 'value') {
      return <div>{this.renderScale()}</div>;
    }

    return (
      <div>
        <QuestionText
          gotoNextQuestion={this.next}
          onChange={this.onTextChange}
          text={question.answer.text}
          isMobile={isMobile}
          onFocus={onFocus}
          onBlur={onBlur}
          inputFocused={inputFocused}
        />
      </div>
    );
  }

  renderScale() {
    const {
      isSubmitting,
      question,
      t,
      onComment,
      onClick,
      onFocus,
      onBlur,
      isMobile,
      inputFocused,
      isSkipping,
    } = this.props;

    const props = {
      inputFocused,
      isMobile,
      isSkipping,
      isSubmitting,
      next: this.next,
      onAnimationEnd: this.onAnimationEnd,
      onAnimationStart: this.onAnimationStart,
      onBlur,
      onChange: this.onScaleChange,
      onClick: () => onClick(question.id),
      onComment,
      onFocus,
      onScaleSet: this.onScaleSet,
      onScaleUnset: this.onScaleUnset,
      question,
      t,
    };

    return (
      <div>
        <QuestionScale {...props} />
      </div>
    );
  }

  renderPreviousButton(mobileVersion) {
    const { isLoading, question, t, hasPreviousQuestion } = this.props;

    if (isLoading || !question) {
      return null;
    }

    return (
      <div
        className={cx(styles.previous, {
          'u-hidden-mobile': !mobileVersion,
          'u-visible-mobile': mobileVersion,
          [styles.previousFixed]: mobileVersion,
        })}
      >
        <Button
          variant="secondary"
          onClick={this.previous}
          disabled={this.state.disablePreviousClick}
          data-test-id="previous"
          icon={<ChevronUp />}
          iconPlacement="end"
          size={mobileVersion ? 'large' : 'medium'}
        >
          {hasPreviousQuestion
            ? t('survey__navigation__previous')
            : t('survey__navigation__previous_no_question')}
        </Button>
      </div>
    );
  }

  renderNextButton(mobileVersion, forceNext) {
    const { isLoading, question, hasNextQuestion, t, questionGroup } =
      this.props;

    if (isLoading || !question) {
      return null;
    } else if (
      forceNext ||
      isNumber(question.answer.scale) ||
      question.answer.comment ||
      question.answer.text
    ) {
      return (
        <Button
          variant="primary"
          onClick={() => {
            this.next();
            if (questionGroup) {
              // eslint-disable-next-line @peakon/peakon/use-plain-string-analytics-event-names
              analytics.track(NEXT_QUESTION, {
                score: isNumber(question.answer?.scale),
                comment: Boolean(question.answer?.comment),
                category_group: questionGroup,
              });
            }
          }}
          data-test-id="next"
          icon={<ChevronDown />}
          iconPlacement="end"
          size={mobileVersion ? 'large' : 'medium'}
        >
          {hasNextQuestion
            ? t('survey__navigation__next')
            : t('survey__navigation__next_no_question')}
        </Button>
      );
    }

    return this.renderSkipButton(mobileVersion);
  }

  renderSkipButton(mobileVersion = false) {
    const {
      hasNextQuestion,
      onClick,
      confirmSubmit,
      onSkipCancel,
      isMobile,
      isSkipping,
      onSkipStart,
      question,
    } = this.props;

    return (
      <SkipQuestionButton
        currentReason={question.answer.skipReason}
        hasNextQuestion={hasNextQuestion}
        isMobile={isMobile}
        isSkipping={isSkipping}
        onCancel={() => onSkipCancel(question.id)}
        onCommentClick={() => onClick(question.id)}
        onNext={this.next}
        onSkip={this.skipQuestion}
        onStart={onSkipStart}
        isShowingConfirmSubmit={confirmSubmit}
        isShowingComment={question.answer.showComment}
        showCommentButton={
          question.type === 'scale' || question.type === 'value'
        }
      />
    );
  }

  render() {
    const {
      inputFocused,
      question,

      confirmSubmit,
    } = this.props;
    const { direction } = this.state;

    return (
      <div
        className={cx(styles.root, {
          [styles.focused]: inputFocused,
          [styles.hasReason]: question && question.answer.skipReason,
        })}
        onTouchStart={this.onTouchStart}
      >
        {this.renderPreviousButton(true)}
        <div className={styles.container}>
          {this.renderSidebar()}
          <div
            className={styles.content}
            role="main"
            aria-labelledby="question-item-title"
          >
            {this.renderPreviousButton()}
            <div
              key={question ? question.id : 'loader'}
              className={cx(styles.question, {
                [questionTransition.enterNext]:
                  !confirmSubmit && direction === 'next' && question,
                [questionTransition.enterPrevious]:
                  !confirmSubmit && direction === 'previous' && question,
              })}
              data-test-id="question-container"
            >
              {this.renderQuestionOrSpinner()}
              <div className={cx(styles.action, 'u-hidden-mobile')}>
                {this.renderNextButton()}
              </div>
            </div>
          </div>
        </div>
        <div
          className={cx(styles.action, 'u-visible-mobile', styles.actionFixed)}
        >
          {this.renderNextButton(true, question && question.answer.skipReason)}
        </div>
      </div>
    );
  }
}

QuestionItem.propTypes = {
  t: PropTypes.func,
  onAnswer: PropTypes.func,
  onScaleSet: PropTypes.func,
  onScaleUnset: PropTypes.func,
  onComment: PropTypes.func,
  onClick: PropTypes.func,
  onSubmit: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  question: PropTypes.instanceOf(Question),
  isLoading: PropTypes.bool,
  isSkipping: PropTypes.bool,
  onSkipCancel: PropTypes.func,
  onSkipStart: PropTypes.func,
  onSkip: PropTypes.func,
  confirmSubmit: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isMobile: PropTypes.bool,
  inputFocused: PropTypes.bool,
  hasPreviousQuestion: PropTypes.bool,
  hasNextQuestion: PropTypes.bool,
  hideIllustrations: PropTypes.bool,
  questionIndex: PropTypes.number,
  estimated: PropTypes.number,
  questionGroup: PropTypes.string,
};

export default withTranslation()(QuestionItem);
