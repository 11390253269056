import React, { Component } from 'react';

import classnames from 'classnames';
import { Header, Attributes } from 'components';
import assign from 'lodash/assign';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import storejs from 'store';

import { setReadOnlyAttributes } from 'actions/attributes';
import * as AuthenticateActions from 'actions/authenticate';
import * as ResponseActions from 'actions/response';
import getQueryFromLocation from 'utils/getQueryFromLocation';

import styles from './styles.scss';
import { getCustomConsentStatus } from '../../actions/customConsent';
import { responseIdSelector } from '../../selectors/ResponseIdSelectors';

class AttributesForm extends Component {
  static propTypes = {
    responseId: PropTypes.string,
    actions: PropTypes.object,
    isKiosk: PropTypes.bool,
    isMobile: PropTypes.bool,
    isPreview: PropTypes.bool,
    isLoading: PropTypes.bool,
    kioskCode: PropTypes.string,
    kioskPublic: PropTypes.bool,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    scheduleCode: PropTypes.string,
    t: PropTypes.func,
  };

  async componentDidMount() {
    const {
      actions: {
        getUserAttributesValues,
        setReadOnlyAttributes,
        userAttributesFetched,
        getCustomConsentStatus,
      },
      isPreview,
      scheduleCode,
      responseId,
    } = this.props;

    if (!isPreview) {
      await getUserAttributesValues();
    }
    await getCustomConsentStatus(responseId);

    // get attributes previously stored from URL
    // see containers/Kiosk getAttributesFromUrl()
    const sessionAttributes = storejs.get(`attributes_${scheduleCode}`);
    if (!isEmpty(sessionAttributes)) {
      userAttributesFetched({
        attributes: sessionAttributes,
      });

      // users cannot change attributes set via URL
      setReadOnlyAttributes(Object.keys(sessionAttributes));
    }
  }

  render() {
    const {
      isLoading,
      scheduleCode,
      kioskCode,
      kioskPublic,
      isKiosk,
      isMobile,
      isPreview,
      t,
    } = this.props;

    const hasScore = this.hasScore();

    return (
      <div
        className={classnames(styles.root, {
          [styles.withBanner]: hasScore,
          [styles.preview]: isPreview,
        })}
      >
        <div
          className={styles.header}
          role="contentinfo"
          aria-label={t('aria__language_selector')}
        >
          <Header
            scheduleCode={scheduleCode}
            kioskCode={kioskCode}
            kioskPublic={kioskPublic}
            isKiosk={isKiosk}
            isMobile={isMobile}
          />
        </div>

        <div
          className={styles.form}
          role="main"
          aria-label={t('survey__reward__profile_form_title')}
        >
          <Attributes isLoading={isLoading} />
        </div>
      </div>
    );
  }

  hasScore() {
    const { location } = this.props;
    const queryParams = getQueryFromLocation(location);
    const score = parseInt(queryParams.get('score'), 10);
    return isNumber(score) && score >= 0 && score <= 10;
  }
}

const mapStateToProps = (state) => {
  return {
    responseId: responseIdSelector(state),
    isKiosk: state.getIn(['survey', 'isKiosk']),
    isPreview: state.getIn(['survey', 'isPreview']),
    isLoading:
      state.getIn(['attributes', 'isLoadingValues']) ||
      state.getIn(['attributes', 'isLoading']),
    isMobile: state.getIn(['ui', 'isMobile']),
    kioskCode: state.getIn(['survey', 'kioskCode']),
    scheduleCode: state.getIn(['survey', 'scheduleCode']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      assign(
        {
          setReadOnlyAttributes,
          getCustomConsentStatus,
        },
        ResponseActions,
        AuthenticateActions,
      ),
      dispatch,
    ),
  };
};

export const AttributesFormContainer = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(AttributesForm)),
);
