import React from 'react';

import { createRoot } from 'react-dom/client';

import '@peakon/components/dist/Button/styles.css';
import '@peakon/components/dist/Overlay/styles.css';
import '@peakon/components/dist/SlideIn/styles.css';

import App from './components/App';
import './errorReporter';
import { localize } from './i18n';
import './scss/base.scss';

import './scss/inline.css';
import './scss/variables.css';

const container = window.document.getElementById('survey-app');
const root = createRoot(container);

localize(() => {
  root.render(<App />);
});
