import { languages, findLanguage } from 'lib/languageUtil';

export const getLocale = (id) => {
  return findLanguage(id);
};

export const getOptions = ({ locales } = {}) => {
  if (Array.isArray(locales) && locales.length) {
    return languages.filter((option) => locales.includes(option.id));
  }

  return languages;
};
