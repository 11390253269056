import moment from 'moment';

import * as localization from '@peakon/localization';

const loadMomentLocale = (locale) =>
  locale.moment !== 'en' && import(`moment/locale/${locale.moment}`);

const changeAsyncLanguages = async (i18n, language) => {
  const locale = localization.resolve({ employee: true }, language);

  await loadMomentLocale(locale);

  moment.locale(locale.moment);
};

export default changeAsyncLanguages;
