import React from 'react';

import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { UtilityGlobeIcon as GlobeIcon } from '@peakon/bedrock/icons/system';
import { Select } from '@peakon/bedrock/react/form';

import * as LocaleActions from 'actions/locale';

import { getLocale, getOptions } from './utils';
import i18n from '../../i18n';

const LanguagePicker = ({
  locales,
  locale,
  forcedLocale,
  localeActions,
  isAuthenticated,
  isKiosk,
}) => {
  const { t } = useTranslation();

  const options = sortBy(
    getOptions({
      locales,
    }).map(({ id, title }) => ({
      value: id,
      label: title,
    })),
    'label',
  );

  const selectedItem = options.find((option) => option.value === locale);

  const onChange = ({ target }) => {
    const newOption = options.find((option) => option.value === target.value);

    if (!newOption) {
      return;
    }

    return isKiosk && !isAuthenticated
      ? localeActions.setLocale(newOption.value)
      : localeActions.changeLocale(newOption.value);
  };

  if (forcedLocale) {
    return null;
  }

  return (
    <div data-test-id="language-picker">
      <Select
        hideLabel
        onChange={onChange}
        value={selectedItem?.value}
        id="language-selector"
        label={t('aria__language_selector')}
      >
        {options.map(({ value, label }) => (
          <Select.Option
            key={value}
            value={value}
            hideIconInList
            icon={<GlobeIcon />}
          >
            {label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

LanguagePicker.propTypes = {
  isKiosk: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  localeActions: PropTypes.object,
  forcedLocale: PropTypes.bool,
  locale: PropTypes.string,
  locales: PropTypes.array,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.getIn(['authenticate', 'isAuthenticated']),
  forcedLocale: state.getIn(['locale', 'forcedLocale']),
  locale: getLocale(state.getIn(['locale', 'locale']) || i18n.language).id,
  locales: state.getIn(['locale', 'availableLocales']),
});

const mapDispatchToProps = (dispatch) => ({
  localeActions: bindActionCreators(LocaleActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePicker);
