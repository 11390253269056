import React from 'react';

import classnames from 'classnames';
import { List } from 'immutable';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { AccessLockIcon as LockIcon } from '@peakon/bedrock/icons/system';
import { Typography, Spinner } from '@peakon/components';
import { CardList } from '@peakon/components/dist/Card';

import CloseButton from 'components/CloseButton';
import Text from 'components/Text';
import getWebHost from 'utils/getWebHost';

import {
  PrioritiesCard,
  ActionsCard,
  TopDriverCard,
  BottomDriverCard,
  LearnMoreCard,
  ScoreChangeCard,
} from './Card';
import PersonalDashboardCta from './PersonalDashboardCta';
import Sidebar from './Sidebar';
import styles from './styles.scss';
import { getLoginHref } from './utils';

function getCards({
  summary,
  hasEmployeeDashboard,
  activated,
  loginHref,
  isRenderedInIframe,
}) {
  const prioritiesCount = get(
    summary.employeeDashboardTeaser,
    'prioritiesCount',
    null,
  );
  const actionsCount = get(
    summary.employeeDashboardTeaser,
    'actionsCount',
    null,
  );

  const changesSign = summary?.changes?.previous?.sign;
  const hasPriorities = prioritiesCount !== null && prioritiesCount > 0;
  const hasActions = actionsCount !== null && actionsCount > 0;

  const cards = [];

  if (hasEmployeeDashboard && activated && (hasPriorities || hasActions)) {
    hasPriorities &&
      cards.push(
        <PrioritiesCard
          key="priorities"
          count={prioritiesCount}
          hasEmployeeDashboard={hasEmployeeDashboard}
          loginHref={loginHref}
          isRenderedInIframe={isRenderedInIframe}
        />,
      );

    hasActions &&
      cards.push(
        <ActionsCard
          key="actions"
          count={actionsCount}
          hasEmployeeDashboard={hasEmployeeDashboard}
          loginHref={loginHref}
          isRenderedInIframe={isRenderedInIframe}
        />,
      );
  } else if (summary.topCategory || summary.bottomCategory) {
    summary.topCategory &&
      cards.push(<TopDriverCard key="topCategory" summary={summary} />);
    summary.bottomCategory &&
      cards.push(<BottomDriverCard key="bottomCategory" summary={summary} />);
  } else {
    hasEmployeeDashboard &&
      loginHref &&
      cards.push(
        <LearnMoreCard
          key="learnMore"
          loginHref={loginHref}
          isRenderedInIframe={isRenderedInIframe}
        />,
      );
    changesSign &&
      hasEmployeeDashboard &&
      loginHref &&
      cards.push(
        <ScoreChangeCard
          key="scoreChange"
          sign={changesSign}
          loginHref={loginHref}
          isRenderedInIframe={isRenderedInIframe}
        />,
      );
  }

  return cards;
}

export function ThankYouPage(props) {
  const {
    summary,
    user,
    session = {},
    addOns = new List(),
    isNative = false,
    isRenderedInIframe = false,
    contextName,
  } = props;

  const { t } = useTranslation();

  const hasEmployeeDashboardRights = get(session, 'rights', []).includes(
    'employeeDashboard:access',
  );
  const hasEmployeeDashboard = hasEmployeeDashboardRights && !isNative;

  const { activated } = summary.activationState || {};

  const loginHref = getLoginHref(summary.activationState);

  if (summary.enabled && summary.isLoading) {
    return (
      <div className={styles.loading}>
        <Spinner />
      </div>
    );
  }

  const cardList = getCards({
    summary,
    hasEmployeeDashboard,
    activated,
    loginHref,
    addOns,
    isNative,
    isRenderedInIframe,
  });

  const hasCards = cardList?.length > 0;

  return (
    <div className={classnames(styles.root, { [styles.isNative]: isNative })}>
      <div className={styles.main}>
        <div className={styles.anonymity}>
          <LockIcon />
          <a
            href={`${getWebHost()}/what-is-peakon`}
            rel="noopener noreferrer"
            target="_blank"
            className="peak-link"
          >
            <Text>survey__reward__anonymous__confidential_v2</Text>
          </a>
        </div>
        <Typography.H1 data-test-id="thank-you-title">
          {t('employee_dashboard__v2__complete', {
            firstName: get(user, 'attributes.firstName'),
          })}
        </Typography.H1>

        {summary.enabled &&
        summary.firstSurveyAnswer !== undefined &&
        hasCards ? (
          <p>
            {hasEmployeeDashboard
              ? t('employee_dashboard__v2__input')
              : t('employee_dashboard__v2__input_non_access')}
          </p>
        ) : (
          <p>
            {t('survey__thanks__help', {
              company: contextName,
            })}
          </p>
        )}

        {summary.enabled && summary.firstSurveyAnswer !== undefined && (
          <div className={styles.cards}>
            <CardList>{cardList}</CardList>
          </div>
        )}
        {hasEmployeeDashboard && !isRenderedInIframe && (
          <PersonalDashboardCta activated={activated} loginHref={loginHref} />
        )}
      </div>
      <Sidebar {...props} />
      {isNative && <CloseButton />}
    </div>
  );
}

ThankYouPage.propTypes = {
  contextName: PropTypes.string,
  summary: PropTypes.object,
  user: PropTypes.shape({
    attributes: PropTypes.shape({
      firstName: PropTypes.string,
    }),
  }),
  session: PropTypes.object,
  actions: PropTypes.object,
  addOns: PropTypes.instanceOf(List),
  locale: PropTypes.string,
  isNative: PropTypes.bool,
  isRenderedInIframe: PropTypes.bool,
};

export default ThankYouPage;
