const compact = require('lodash/compact');
const uniq = require('lodash/uniq');

const localization = require('@peakon/localization');

const options = {
  employee: true,
};

const languages = localization.all(options);

function findLanguage(locale) {
  return localization.resolve(options, locale);
}

function getSupportedLocales() {
  const locales = languages.map((lang) => lang.id);
  const languageLocales = compact(
    locales.map((locale) => locale.split('-')[0]),
  );

  return uniq(locales.concat(languageLocales));
}

module.exports = {
  languages,
  findLanguage,
  getSupportedLocales,
};
