import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi, { HttpBackendOptions } from 'i18next-http-backend';

import * as localization from '@peakon/localization';

import changeAsyncLanguages from 'lib/changeAsyncLanguages';
import { getSupportedLocales } from 'lib/languageUtil';

import { messageFormatProcessor } from 'messageFormatPostProcessor';
import errorReporter from 'errorReporter';

i18n.use(messageFormatProcessor).use(HttpApi).use(LanguageDetector);

export const i18nOptions: InitOptions = {
  supportedLngs: getSupportedLocales(),
  fallbackLng: 'en',
  load: 'currentOnly',
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for React
  },
  ns: ['survey'],
  defaultNS: 'survey',
  backend: {
    request: (async (_options, url: string, _payload, callback) => {
      const language = localization.resolve(
        {
          employee: true,
        },
        url,
      );

      const translations = await import(`../locales/${language.id}`);

      callback(null, {
        data: {
          ...translations,
        },
        status: 200,
      });
    }) satisfies HttpBackendOptions['request'],
    loadPath: '{{lng}}',
    parse: (data: unknown) => data,
  },
  detection: {
    order: ['navigator'],
  },
  postProcess: [messageFormatProcessor.name],
  returnObjects: false,
  returnedObjectHandler: (key, _value, options) => {
    return i18n.t(`${key}.message`, options);
  },
  saveMissing: true,
  missingKeyHandler(lngs, _ns, key) {
    // Not reporting on production, strictly because I'm not sure if it'd flood Rollbar
    if (ENV.environment !== 'production') {
      errorReporter.error(
        new Error(
          `Missing translation key: "${key}", lngs: "${lngs.join(', ')}"`,
        ),
      );
    }
  },
};

const setLocaleMeta = () => {
  const title = i18n.t('surveyform__title');

  setTimeout(() => {
    window.document.title = `${title} - Workday Peakon Employee Voice`;
    window.document.documentElement.setAttribute('lang', i18n.language);
  }, 0);
};

export const localize = (callback: () => unknown) => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  i18n.init(i18nOptions, async (err) => {
    if (err) {
      i18n.addResourceBundle(
        'en',
        'survey',
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        require('../locales/en.json'),
        true,
        true,
      );
    }

    await changeAsyncLanguages(i18n, i18n.language);
    setLocaleMeta();

    i18n.on('languageChanged', (language) => {
      setLocaleMeta();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      changeAsyncLanguages(i18n, language);
    });

    callback();
  });
};

export const setRtl = (isRtl: boolean) => {
  window.document.getElementsByTagName('html')[0].dir = isRtl ? 'rtl' : 'ltr';
};

export default i18n;
