import React from 'react';
import { useTranslation } from 'react-i18next';

import { Header, KioskForm } from 'components';
import { Context } from 'records';

import styles from './KioskContent.scss';
import { KioskMode } from 'constants/kiosk';

export const KioskContent = ({
  noSurveys = false,
  handleBack,
  notification,
  showNotification = false,
  isSubmitting = false,
  isAuthenticating = false,
  handleSubmit,
  kioskMode,
  error,
  context,
}: {
  noSurveys?: boolean;
  handleBack: () => void;
  notification: object;
  showNotification?: boolean;
  isSubmitting?: boolean;
  isAuthenticating?: boolean;
  handleSubmit: () => void;
  kioskMode: KioskMode;
  error: { type: string; error: Error };
  context: Context;
}) => {
  const { t } = useTranslation();

  const Logo = () => {
    if (!context?.logo && !context?.name) {
      return null;
    }
    const { logo, name } = context;

    return (
      <div className={styles.logo}>
        {logo ? (
          <img
            src={`${logo}?fit=max&w=310&h=180`}
            alt=""
            data-test-id="kiosk-logo"
          />
        ) : (
          kioskMode !== 'ephemeral' && (
            <h1 className={styles.contextName}>{name}</h1>
          )
        )}
      </div>
    );
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.header}>
          <Header isHome={false} isKiosk context={context} />
        </div>
        <div className={styles.form}>
          <Logo />
          {noSurveys ? (
            <div className={styles.noSurvey}>
              <h2 className={styles.noSurveyTitle}>{t('no_surveys__title')}</h2>
              <div className={styles.noSurveyText}>{t('no_surveys__text')}</div>
              <div className={styles.formToggler}>
                <a onClick={handleBack}>{t('kiosk__back')}</a>
              </div>
            </div>
          ) : (
            <KioskForm
              notification={notification}
              showNotification={showNotification}
              isSubmitting={isSubmitting}
              isAuthenticating={isAuthenticating}
              handleSubmit={handleSubmit}
              kioskMode={kioskMode}
              error={error}
              context={context}
            />
          )}
        </div>
      </div>
    </div>
  );
};
