import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import Text from 'components/Text';

import styles from './SurveyInfo.scss';
import SurveyInfoStats from '../SurveyInfoStats';

const FREQUENCIES = {
  0: 'manual',
  1: 'weekly',
  2: 'bi_weekly',
  4: 'monthly',
};

export default function SurveyInfo({ contextName, info, t }) {
  const key = FREQUENCIES[info.frequency] || 'custom';
  const delimiter = uuidv4();

  return (
    <div className={styles.root} data-test-id="survey-info-wrapper">
      <div
        className={styles.frequency}
        data-test-id="survey-info-frequency-wrapper"
      >
        <Text
          preRender={(translated) => {
            const [start, mid, end] = translated.split(delimiter);

            return (
              <Fragment>
                {start}
                <strong>{mid}</strong>
                {end}
              </Fragment>
            );
          }}
          values={{
            company: contextName,
            emphasis_start: delimiter,
            emphasis_end: delimiter,
          }}
        >
          {`survey__welcome__your_survey__${key}`}
        </Text>
      </div>
      <SurveyInfoStats info={info} t={t} />
    </div>
  );
}

SurveyInfo.propTypes = {
  contextName: PropTypes.string,
  info: PropTypes.object.isRequired,
  t: PropTypes.func,
};
