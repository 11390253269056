import React from 'react';

import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { IconButton } from '@peakon/bedrock/react/button';

import { getCurrentQuestionGroup } from 'selectors/QuestionSelectors';
import { CHANGE_SCORE } from 'constants/mixpanelEvents';
import { analytics } from 'utils/features/analytics/analytics';

import styles from './ScoreButton.scss';

export default function ScoreButton({ onClick, score }) {
  const currentQuestionSet = useSelector(getCurrentQuestionGroup);
  const { t } = useTranslation();
  const hasScore = isNumber(score);

  return (
    <div className={styles.root}>
      <IconButton
        rounded
        variant={hasScore ? 'primary' : 'secondary'}
        size="large"
        onClick={() => {
          onClick();
          currentQuestionSet
            ? // eslint-disable-next-line @peakon/peakon/use-plain-string-analytics-event-names
              analytics.track(CHANGE_SCORE, {
                category_group: currentQuestionSet,
              })
            : // eslint-disable-next-line @peakon/peakon/use-plain-string-analytics-event-names
              analytics.track(CHANGE_SCORE);
        }}
        icon={hasScore ? score : '–'}
        data-test-id="score"
        id="changeScoreBtn"
        aria-label={
          hasScore
            ? t('survey__a11y_answer__change', { score })
            : t('survey__a11y_answer__change-no-score')
        }
        accessibleName={
          hasScore
            ? t('survey__a11y_answer__change', { score })
            : t('survey__a11y_answer__change-no-score')
        }
      />

      <label
        htmlFor="changeScoreBtn"
        className={styles.help}
        data-test-id="change-score"
      >
        {t('survey__answer__change')}
      </label>
    </div>
  );
}

ScoreButton.propTypes = {
  onClick: PropTypes.func,
  score: PropTypes.number,
};
