import { reasons } from 'constants/skipReasons';

import React, { Fragment, useEffect, useRef } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { NavigationBasicNavigationChevronDownIcon as ChevronDown } from '@peakon/bedrock/icons/system';
import { Button, UnstyledButton } from '@peakon/bedrock/react/button';

import Modal from 'components/Modal';
import { SKIP_QUESTION } from 'constants/mixpanelEvents';
import { analytics } from 'utils/features/analytics/analytics';
import { getCurrentQuestionGroup } from 'selectors/QuestionSelectors';

import styles from './SkipQuestionButton.scss';

type Reason = (typeof reasons)[number];

type SkippingModalProps = {
  isMobile: boolean;
  isShowingComment: boolean;
  isShowingConfirmSubmit: boolean;
  currentReason?: Reason;
  hasNextQuestion: boolean;
  showCommentButton: boolean;
  onSkip: (reason: Reason) => void;
  onNext: () => void;
  onCancel: () => void;
  onCommentClick: () => void;
  focusSkipQuestionButton: () => void;
};

export type SkipQuestionButtonProps = SkippingModalProps & {
  isSkipping: boolean;
  onStart: () => void;
};

const SkippingModal = ({
  isMobile,
  isShowingComment,
  isShowingConfirmSubmit,
  currentReason,
  hasNextQuestion,
  showCommentButton,
  onSkip,
  onNext,
  onCancel,
  onCommentClick,
  focusSkipQuestionButton,
}: SkippingModalProps) => {
  const id = uuidv4();
  const { t } = useTranslation();
  const firstReasonButtonRef = useRef(null);
  const shouldRenderCommentButton =
    isMobile && !currentReason && showCommentButton && !isShowingComment;

  useEffect(() => focusSkipQuestionButton, [focusSkipQuestionButton]);

  function handleCommentClick() {
    if (isMobile && !isShowingComment) {
      onCancel();
    }
    onCommentClick();
  }

  function renderButtons() {
    const buttons = [];

    const skipReasonTranslationMap = {
      dont_apply: t('survey__skip__dont_apply'),
      dont_understand: t('survey__skip__dont_understand'),
      dont_know: t('survey__skip__dont_know'),
      other: t('survey__skip__other'),
    };

    reasons.forEach((reason, index) => {
      if (!currentReason || currentReason === reason) {
        buttons.push(
          <div key={reason} className={styles.buttonReasonWrapper}>
            <Button
              ref={index === 0 ? firstReasonButtonRef : null}
              data-test-id={`skip-${reason}`}
              variant="secondary"
              disabled={currentReason === reason}
              onClick={() => onSkip(reason)}
            >
              {skipReasonTranslationMap[reason]}
            </Button>
          </div>,
        );
      }
    });

    if (currentReason) {
      buttons.push(
        <UnstyledButton
          key="cancel"
          className={cx(styles.cancel, 'u-visible-mobile')}
          data-test-id="cancel-skip_mobile"
          accessibleName={t('survey__skip__cancel')}
          onClick={onCancel}
        >
          {t('survey__skip__cancel')}
        </UnstyledButton>,
      );

      buttons.push(
        <Button
          key="next"
          variant="primary"
          data-test-id="next"
          icon={<ChevronDown />}
          iconPlacement="end"
          onClick={onNext}
        >
          {hasNextQuestion
            ? t('survey__navigation__next')
            : t('survey__navigation__next_no_question')}
        </Button>,
      );
    }

    return buttons;
  }

  return (
    <Modal
      onClose={onCancel}
      refToFocusOn={firstReasonButtonRef}
      className={cx(styles.skippingModal, {
        [styles.hasReason]: currentReason,
      })}
    >
      <div className={styles.skippingModalHeader}>
        <h2
          id={id}
          className={styles.title}
          aria-live={isShowingConfirmSubmit ? undefined : 'polite'}
        >
          {t('survey__skip__title')}
        </h2>
        <UnstyledButton
          accessibleName={t('survey__skip__cancel')}
          data-test-id="cancel-skip_desktop"
          className={cx(styles.cancel, styles.cancelHeader, {
            'u-hidden-mobile': currentReason,
          })}
          onClick={onCancel}
        >
          {t('survey__skip__cancel')}
        </UnstyledButton>
      </div>
      <div
        aria-labelledby={id}
        role="group"
        className={cx(styles.actions, {
          [styles.actionsSkipped]: currentReason,
        })}
      >
        {renderButtons()}
      </div>
      {shouldRenderCommentButton ? (
        <Fragment>
          <div className={styles.commentTitle}>
            <span>{t('survey__or')}</span>
          </div>
          <div className={styles.commentButtonWrapper}>
            <Button
              variant="secondary"
              onClick={handleCommentClick}
              data-test-id="comment-without-score"
            >
              {t('survey__skip__comment')}
            </Button>
          </div>
        </Fragment>
      ) : null}
    </Modal>
  );
};

export const SkipQuestionButton = (props: SkipQuestionButtonProps) => {
  const { currentReason, isMobile, isShowingComment, isSkipping, onStart } =
    props;
  const { t } = useTranslation();
  const skipQuestionButtonRef = useRef<HTMLButtonElement>(null);
  const currentQuestionSet = useSelector(getCurrentQuestionGroup);

  function focusSkipQuestionButton() {
    skipQuestionButtonRef.current?.focus();
  }

  if (
    (!isSkipping && isMobile && isShowingComment) ||
    (!isSkipping && !currentReason)
  ) {
    return (
      <Button
        ref={skipQuestionButtonRef}
        variant="secondary"
        data-test-id="skip"
        icon={<ChevronDown />}
        iconPlacement="end"
        size={isMobile ? 'large' : 'medium'}
        onClick={() => {
          onStart();
          currentQuestionSet
            ? // eslint-disable-next-line @peakon/peakon/use-plain-string-analytics-event-names
              analytics.track(SKIP_QUESTION, {
                category_group: currentQuestionSet,
              })
            : // eslint-disable-next-line @peakon/peakon/use-plain-string-analytics-event-names
              analytics.track(SKIP_QUESTION);
        }}
      >
        {t('survey__navigation__skip')}
      </Button>
    );
  } else {
    return (
      <SkippingModal
        {...props}
        focusSkipQuestionButton={focusSkipQuestionButton}
      />
    );
  }
};
