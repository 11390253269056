import React from 'react';

import classnames from 'classnames';
import { OrderedSet, Map } from 'immutable';
import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Answer, Question } from 'records';

import { UnstyledButton } from '@peakon/bedrock/react/button';

import styles from './styles.scss';
import TimeLeft from './TimeLeft';
import Tooltip from './Tooltip';

const Item = ({
  answer,
  isAnswered,
  isCurrentQuestion,
  isMobile,
  isSkipped,
  onClick,
  question,
  testId,
  questionIndex,
  estimated,
}) => {
  const { t } = useTranslation();

  const isAnsweredOrSkipped = isAnswered || isSkipped;
  const hasScale = isNumber(answer.scale);

  const className = classnames(styles.item, {
    [styles.current]: isCurrentQuestion,
    [styles.answered]: !isCurrentQuestion && isAnsweredOrSkipped,
    [styles.muted]:
      !isCurrentQuestion && ((isAnswered && !hasScale) || isSkipped),
  });

  const score = hasScale ? answer.scale : '-';

  const tooltipId = `tooltip-question-${question.id}`;

  const label = t('aria__questions_progress_score', {
    questionIndex,
    estimated,
    score,
  });

  return (
    <div className={styles.itemWrapper} data-test-id={`${testId}`}>
      {/* Noninteractive bullet for tablet and below - toggled with media query */}
      <div className={classnames(className, styles.itemTablet)}>
        <div className={styles.bullet} />
      </div>

      {/* Interactive bullet for desktop - toggled with media query */}
      <UnstyledButton
        accessibleName={label}
        className={classnames(className, styles.itemDesktop)}
        onClick={onClick}
        aria-describedby={tooltipId}
      >
        <div className={styles.bullet}>
          {isAnsweredOrSkipped && (
            <div className={styles.scale} data-test-id="progress-scale">
              {score}
            </div>
          )}
        </div>

        {!isMobile && !isCurrentQuestion && isAnsweredOrSkipped && (
          <div className={styles.tooltip}>
            <Tooltip
              id={tooltipId}
              question={question.text}
              comment={answer.comment || answer.text}
              skipReason={!isAnswered ? answer.skipReason : null}
              value={question.value && question.value.name}
            />
          </div>
        )}
      </UnstyledButton>
    </div>
  );
};

Item.propTypes = {
  answer: PropTypes.instanceOf(Answer).isRequired,
  isAnswered: PropTypes.bool,
  isCurrentQuestion: PropTypes.bool,
  isMobile: PropTypes.bool,
  isSkipped: PropTypes.bool,
  onClick: PropTypes.func,
  question: PropTypes.instanceOf(Question).isRequired,
  testId: PropTypes.string,
  questionIndex: PropTypes.number.isRequired,
  estimated: PropTypes.number.isRequired,
};

export const ProgressBar = ({
  answers,
  currentQuestion,
  estimated,
  isMobile,
  onChangeQuestion,
  questions,
  seconds,
}) => {
  const items = [];
  let answered = 0;
  let questionIndex = 0;
  const fill = estimated - questions.size;

  questions.forEach((question) => {
    const answer = answers.get(question.id, {});
    const isCurrentQuestion = question.id === currentQuestion;

    const isAnswered = answer.isAnswered();
    const isSkipped = answer.isSkipped();

    questionIndex++;

    if ((isAnswered || isSkipped) && !isCurrentQuestion) {
      answered++;
    }

    items.push(
      <Item
        key={question.id}
        answer={answer}
        isAnswered={isAnswered}
        isCurrentQuestion={isCurrentQuestion}
        isMobile={isMobile}
        isSkipped={isSkipped}
        onClick={() => !isMobile && onChangeQuestion(question.id)}
        question={question}
        testId={`progress-item-${question.id}`}
        questionIndex={questionIndex}
        estimated={estimated}
      />,
    );
  });

  for (let i = 0; i < fill; i += 1) {
    items.push(
      <div
        key={`fill-${i}`}
        className={classnames(styles.item, styles.unknown)}
      >
        <div className={styles.bullet} />
      </div>,
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.bar} data-test-id="progress-bar">
        <div
          className={styles.background}
          style={{
            [isMobile ? 'maxWidth' : 'maxHeight']: `${
              (100 / estimated) * answered + 50 / estimated
            }%`,
          }}
        />
        <div className={styles.items}>{items}</div>
      </div>
      <div className={styles.time}>
        <TimeLeft answered={answered} estimated={estimated} seconds={seconds} />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  currentQuestion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangeQuestion: PropTypes.func,
  answers: PropTypes.instanceOf(Map).isRequired,
  questions: PropTypes.instanceOf(OrderedSet).isRequired,
  estimated: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
  seconds: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  currentQuestion: state.getIn(['survey', 'currentQuestion']),
  answers: state.get('answers'),
  questions: state.get('questions'),
  estimated: state.getIn(['survey', 'info', 'estimated']),
  isMobile: state.getIn(['ui', 'isMobile']),
  seconds: state.getIn(['survey', 'info', 'seconds']),
});

export default connect(mapStateToProps)(ProgressBar);
