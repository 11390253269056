import React, { useEffect, useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { EditCloseIcon } from '@peakon/bedrock/icons/system';
import { LinkButton, UnstyledButton } from '@peakon/bedrock/react/button';
import { Card } from '@peakon/components';
import preRender from '@peakon/components/dist/utils/preRender';

import styles from './styles.scss';
import api from '../../../api';

const UXFLOW_KEY = 'account';
const UXFLOW_VALUE = 'survey_research_panel';

const ResearchPanel = (props) => {
  const { actions } = props;
  const { t } = useTranslation();
  const [render, setRender] = useState(false);

  const checkShouldRender = useCallback(async () => {
    const { completed } = await api.uxflow.get(UXFLOW_KEY, UXFLOW_VALUE);

    if (!completed) {
      setRender(true);
    }
  }, [setRender]);

  useEffect(() => {
    checkShouldRender();
  }, [checkShouldRender]);

  const handleClose = async () => {
    setRender(false);

    await api.uxflow.complete(UXFLOW_KEY, UXFLOW_VALUE);
    // in containers/ThankYou we postpone the unathentication when
    // the reserach banners is shown, since we need auth for the UX flow
    // so we need to do it now
    await actions.unauthenticate();
  };

  if (!render) {
    return null;
  }

  const delimiter = uuidv4();

  const link =
    'https://peakon.com/research-panel?utm_source=survey&utm_medium=research_panel';

  return (
    <Card className={styles.root} data-test-id="research-panel">
      <UnstyledButton
        className={styles.close}
        onClick={handleClose}
        data-test-id="research-panel-dismiss"
        accessibleName={t('common_close')}
      >
        <EditCloseIcon />
      </UnstyledButton>
      <Card.Content>
        <Card.Title tag="h2">
          {t('survey__reward__research_panel__title')}
        </Card.Title>
        <Card.Text>
          {preRender(
            t('survey__reward__research_panel__message', {
              link: delimiter,
            }),
            [
              {
                delimiter,
                render(value) {
                  return (
                    <a
                      key="link"
                      className="peak-link"
                      href={link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {value}
                    </a>
                  );
                },
              },
            ],
          )}
        </Card.Text>
        <Card.Text>
          <LinkButton
            variant="secondary"
            size="small"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('survey__reward__research_panel__button')}
          </LinkButton>
        </Card.Text>
      </Card.Content>
    </Card>
  );
};

ResearchPanel.propTypes = {
  actions: PropTypes.shape({
    unauthenticate: PropTypes.func,
  }),
};

export default ResearchPanel;
