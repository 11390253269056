import React, { Component, createRef, Fragment } from 'react';

import classnames from 'classnames';
import each from 'lodash/each';
import isFunction from 'lodash/isFunction';
import Mousetrap from 'mousetrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { VisuallyHidden } from '@peakon/bedrock/react/visually-hidden';

import styles from './QuestionScale.scss';
import { ButtonGroup } from './ScaleButton';
import CommentButton from '../CommentButton';
import QuestionComment from '../QuestionComment';
import ScoreButton from '../ScoreButton';

const KEYBOARD_SHORTCUTS = {
  numbers: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
};

class QuestionScale extends Component {
  static propTypes = {
    inputFocused: PropTypes.bool,
    isMobile: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    next: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onAnimationStart: PropTypes.func,
    onAnimationEnd: PropTypes.func,
    onClick: PropTypes.func,
    onComment: PropTypes.func,
    onFocus: PropTypes.func,
    onScaleSet: PropTypes.func,
    onScaleUnset: PropTypes.func,
    question: PropTypes.object.isRequired,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.commentRef = createRef();
    this.buttonGroupRef = createRef();
    this.previousCombo = null;

    const { question } = this.props;

    this.state = {
      isAnimating: false,
      isAnimatingInfo: false,
      forceScale: false,
      scale: question ? question.answer.scale : null,
    };
  }

  componentDidMount() {
    const { isMobile } = this.props;

    if (!isMobile) {
      this.bindKeyboardShortcuts();
    } else if (isFunction(React.initializeTouchEvents)) {
      React.initializeTouchEvents(true);
    }
  }

  componentWillUnmount() {
    const { onAnimationEnd } = this.props;

    this.unbindKeyboardShortcuts();

    // Clear timers
    clearTimeout(this.comboTimer);

    if (this.animationTimer) {
      clearTimeout(this.animationTimer);
      onAnimationEnd && onAnimationEnd();
    }
  }

  bindKeyboardShortcuts = () => {
    Mousetrap.bind(KEYBOARD_SHORTCUTS.numbers, this.onKeyboardNumberPressed);
  };

  unbindKeyboardShortcuts = () => {
    const { isMobile } = this.props;

    if (!isMobile) {
      each(KEYBOARD_SHORTCUTS, (value) => Mousetrap.unbind(value));
    }
  };

  onKeyboardNumberPressed = (event, combo) => {
    const { isSubmitting } = this.props;
    const { isAnimating } = this.state;

    if (isSubmitting || isAnimating) {
      return;
    }

    event.preventDefault();

    // check against potential combo
    const isCombo = combo === '1' || (combo === '0' && this.previousCombo);

    if (isCombo) {
      return this.onCombo(parseInt(combo, 10));
    }

    this.onChangeScore(parseInt(combo, 10));
  };

  onForceScale = () => {
    this.setState(
      {
        isAnimatingInfo: true,
        forceScale: true,
      },
      this.props.onClick,
    );
  };

  onCommentChange = (value) => {
    const { question, onComment } = this.props;

    onComment(question.id, value);
  };

  onCombo = (scale) => {
    // clear any earlier timer
    clearTimeout(this.comboTimer);

    if (scale === 0 && this.previousCombo === 1) {
      return this.onChangeScore(10);
    } else if (scale === 1) {
      this.previousCombo = 1;
      // give a second to the user to actually finish this combo
      this.comboTimer = setTimeout(() => {
        this.previousCombo = null;
        this.onChangeScore(1);
      }, 500);
    } else {
      this.previousCombo = null;
    }
  };

  onChangeScore = (selectedScale) => {
    const { onScaleSet } = this.props;
    const { scale } = this.state;

    // cancel any pending animation
    clearTimeout(this.animationTimer);

    if (selectedScale === scale) {
      return this.onRemoveScore();
    }

    // update scale
    onScaleSet(selectedScale);

    // trigger animation
    return this.setState(
      { scale: selectedScale, isAnimating: true, isAnimatingInfo: false },
      this.onAnimationStart,
    );
  };

  onAnimationStart = () => {
    const { onAnimationStart } = this.props;
    onAnimationStart && onAnimationStart();
    this.animationTimer = setTimeout(this.onAnimationDone, 800);
  };

  onAnimationDone = () => {
    const { onChange, onAnimationEnd } = this.props;
    const { scale } = this.state;

    onChange(scale);

    this.setState(
      {
        isAnimating: false,
      },
      onAnimationEnd,
    );
  };

  onRemoveScore = () => {
    const { onScaleUnset } = this.props;

    return this.setState(
      {
        scale: null,
      },
      () => {
        onScaleUnset();
        this.buttonGroupRef.current && this.buttonGroupRef.current.focus();
      },
    );
  };

  renderButton = () => {
    const { onClick, question } = this.props;
    const { isAnimating } = this.state;

    if (question.answer.showComment) {
      return (
        <div className={styles.button}>
          <ScoreButton onClick={onClick} score={question.answer.scale} />
        </div>
      );
    }

    return (
      <div
        className={classnames(styles.button, 'u-hidden-mobile', {
          [styles.fadeOut]: isAnimating,
        })}
      >
        <CommentButton onClick={this.onForceScale} />
      </div>
    );
  };

  renderComment() {
    const { next, onClick, onBlur, onFocus, question, isMobile, inputFocused } =
      this.props;

    const { isAnimatingInfo } = this.state;

    return (
      <div>
        <QuestionComment
          ref={this.commentRef}
          gotoNextQuestion={next}
          animateInfo={isAnimatingInfo}
          onChange={this.onCommentChange}
          onClick={onClick}
          onBlur={onBlur}
          onFocus={onFocus}
          comment={question.answer.comment}
          score={question.answer.scale}
          isMobile={isMobile}
          inputFocused={inputFocused}
        />
      </div>
    );
  }

  renderContent() {
    const { question, t } = this.props;
    const { isAnimating, scale } = this.state;

    if (question.answer.showComment) {
      return <div className={styles.question}>{this.renderComment()}</div>;
    }

    return (
      <div className={styles.question}>
        <div
          id="radio-group-title"
          className={classnames(styles.choose, {
            [styles.fadeOut]: isAnimating,
          })}
        >
          <span aria-hidden="true">{t('survey__answer__scale__title')}</span>
          <VisuallyHidden>
            {t('survey__answer__scale-explanation')}
          </VisuallyHidden>
        </div>

        <ButtonGroup
          ref={this.buttonGroupRef}
          isAnimating={isAnimating}
          labelledBy="radio-group-title"
          onClick={this.onChangeScore}
          value={scale}
        />
        {this.renderLegend()}
        {isAnimating && (
          <Fragment>
            <div className={styles.commentAnimated}>{this.renderComment()}</div>
          </Fragment>
        )}
      </div>
    );
  }

  renderLegend = () => {
    const { t } = this.props;
    const { isAnimating } = this.state;

    return (
      <div className={styles.infoContainer}>
        <div
          className={classnames(styles.legend, {
            [styles.fadeOut]: isAnimating,
          })}
        >
          <span className={styles.legendItem} aria-hidden>
            {t('survey__answer__scale-0')}
          </span>
          <span className={styles.legendItem} aria-hidden>
            {t('survey__answer__scale-10')}
          </span>
        </div>
      </div>
    );
  };

  render() {
    const { inputFocused } = this.props;

    return (
      <div
        className={classnames(styles.root, {
          [styles.focused]: inputFocused,
        })}
      >
        <div className={styles.container}>
          {this.renderContent()}
          {this.renderButton()}
        </div>
      </div>
    );
  }
}

export default withTranslation()(QuestionScale);
