import React from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import styles from './Footer.scss';

function Footer() {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.links}>
        <span>{t('survey__footer__powered-by-peakon')}</span>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    rawDataExportEnabled:
      state.getIn(['survey', 'rawDataExport', 'enabled']) ||
      state.getIn(['kiosk', 'rawDataExport', 'enabled']),
  };
};

export default connect(mapStateToProps)(Footer);
